/* eslint-disable class-methods-use-this */
import _axios, { get, put, post, _delete } from '@/lin/plugin/axios'

class Activity {
  // 活动列表
  async getActivityList(data) {
    const res = await get('/v1/activity', data)
    return res
  }

  // 活动详情
  async getActivityDetail(id, data) {
    const res = await get(`/v1/activity/${id}`, data)
    return res
  }

  // 新增活动
  async addActivity(data) {
    const res = await post('/v1/activity', data)
    return res
  }

  // 修改活动
  async editActivity(id, data) {
    const res = await put(`/v1/activity/${id}`, data)
    return res
  }

  // 活动活动暂停、开始
  async changeActivityStatus(id, data) {
    const res = await put(`/v1/activity/${id}/switch`, data)
    return res
  }

  // 活动活动结束
  async activityFinish(id, data) {
    const res = await put(`/v1/activity/${id}/finish`, data)
    return res
  }

  // 删除活动
  async deleteActivity(id, data) {
    const res = await _delete(`/v1/activity/${id}`, data)
    return res
  }

  // 活动分类列表-分页
  async getActCateList(data) {
    const res = await get('/v1/act_category', data)
    return res
  }

  // 活动分类列表-全部
  async getActCateListAll(data) {
    const res = await get('/v1/act_category/all', data)
    return res
  }

  // 新增活动分类
  async addActCate(data) {
    delete data.id
    const res = await post('/v1/act_category', data)
    return res
  }

  // 修改活动分类
  async editActCate(data) {
    let { id } = data
    delete data.id
    const res = await put(`/v1/act_category/${id}`, data)
    return res
  }

  // 删除活动分类
  async delActCate(id, data) {
    const res = await _delete(`/v1/act_category/${id}`, data)
    return res
  }
}

export default new Activity()
